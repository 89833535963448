import 'regenerator-runtime/runtime'
import api from "@/services/apiService"

class GameService {

  getBalance() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'game/balance')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getCategories() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'game/category')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getCreators(catId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'game/creator/' + catId)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getGames(creId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'game/' + creId)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  playGame(id) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + 'game/play/' + id)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  deposit(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + 'game/deposit/' + data.uniqueCode, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  withdraw(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + 'game/withdraw/' + data.uniqueCode, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getCredits() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'game/credit')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getHistories(params, perPage=100, offset=0) {
    return new Promise((resolve, reject) => {
      api.get(`${process.env.VUE_APP_API_TICKET}game/history/${params.startDate}/${params.endDate}/${perPage}/${offset}`)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getWinLose(params) {
    return new Promise((resolve, reject) => {
      api.get(`${process.env.VUE_APP_API_TICKET}game/winlose/${params.startDate}/${params.endDate}`)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getWinLoseTxns(params) {
    return new Promise((resolve, reject) => {
      api.get(`${process.env.VUE_APP_API_TICKET}game/winlose/${params.gameId}/${params.startDate}/${params.endDate}/${params.perPage}/${params.offset}`)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  testConnect(username, productId, code) {
    return new Promise((resolve, reject) => {
      api.get(`https://api.all123th.com/webhook/api?productId=${productId}&key=${username}&code=${code}`)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }
}

export default new GameService()
